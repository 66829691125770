<template >
  <SlDialog
    title="确认标签下单"
    :visible.sync="dialogVisible"
    width="800px"
    :loading="submitLoading"
    @closed="handleClose"
    @close="handleClose"
    @submit="handleSubmit"
  >
    <div
      class="red-notice-text"
    >{{ selectedRow.skuQuantity }}个SKU 共计{{ selectedRow.requireQuantity }}个标签；是否确认下单，下单后标签供应商系统会收到标签制作订单</div>
    <SlTable
      align="center"
      :border="true"
      :tableData="tableData"
      :columns="columns"
      :selection="false"
      :operate="false"
      :tooltip="false"
      :isEmbedTable="true"
      rowKey="id"
      class="label-order-table"
    ></SlTable>

    <div class="address-box" v-if="canEditLabelAddress">
      <div class="flex1">
        <div class="address-title">收货信息</div>
        <div>收货地址：{{address.consigneeAddress || '-'}}</div>
        <div>收货姓名：{{address.consigneeName || '-'}}</div>
        <div>收货电话：{{address.consigneeNumber || '-'}}</div>
      </div>
      <div>
        <el-button
          type="text"
          @click="handleUpdateAddress"
        >{{address.consigneeAddress ? '编辑' : '新增'}}地址</el-button>
      </div>
    </div>

    <UpdateAddressDialog
      :address="address"
      :visible.sync="updateAddressDialogVisible"
      @submitSuccess="handleUpdateAddressSuccess"
    ></UpdateAddressDialog>
  </SlDialog>
</template>

<script>
import OemGoodsAPI from '@api/oemGoods'
import UpdateAddressDialog from './UpdateAddressDialog.vue'

export default {
  name: 'LabelOrderDialog',
  components: { UpdateAddressDialog },
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    },
    selectedRow: {
      type: Object,
      default: () => { },
      required: false
    },
    initAddress: {
      type: Object,
      default: () => { },
      required: false
    }
  },
  data () {
    return {
      dialogVisible: false,
      submitLoading: false,
      tableData: [],
      columns: [
        {
          prop: 'skuCode',
          label: 'SKU'
        },
        {
          prop: 'upc',
          label: 'UPC'
        },
        {
          prop: 'requireQuantity',
          label: '订单数量'
        }
      ],
      address: {
        consigneeAddress: '',
        consigneeName: '',
        consigneeNumber: ''
      },
      updateAddressDialogVisible: false
    }
  },
  watch: {
    visible: {
      handler: function (newVal) {
        if (this.dialogVisible !== newVal) {
          this.dialogVisible = newVal
        }
      },
      immediate: true
    },
    selectedRow: {
      handler: function (newVal) {
        const { id } = newVal || {}
        if (!id) return

        this.initTable(newVal)
      },
      immediate: true,
      deep: true
    },
    initAddress: {
      handler: function (newVal) {
        const { consigneeAddress = '', consigneeName = '', consigneeNumber = '' } = newVal || {}
        this.address = { consigneeAddress, consigneeName, consigneeNumber }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    canEditLabelAddress () {
      const { canEditLabelAddress } = this.selectedRow
      return canEditLabelAddress || ''
    }
  },
  methods: {
    initTable (row) {
      const { skuList = [] } = row || {}
      this.tableData = skuList || []
    },
    handleSubmit () {
      this.submitLoading = true
      const { id = '' } = this.selectedRow
      OemGoodsAPI.createLabelOrder(id).then(res => {
        let { success } = res
        if (success) {
          this.$message.success('操作成功')

          this.tableData = []
          this.dialogVisible = false
          this.$emit('update:visible', false)
          this.$emit('submitSuccess', false)
        }
      }).finally(() => {
        this.submitLoading = false
      })
    },
    handleClose () {
      this.tableData = []
      this.dialogVisible = false
      this.$emit('update:visible', false)
    },
    handleUpdateAddress () {
      this.updateAddressDialogVisible = true
    },
    handleUpdateAddressSuccess (newAddress) {
      this.address = { ...newAddress }
    }
  }
}
</script>

<style lang="scss" scoped>
.label-order-table {
  /deep/ .el-table__body-wrapper {
    max-height: 50vh;
    overflow-y: scroll;
  }
}

.address-box {
  margin-top: 40px;
  display: flex;
  align-items: flex-start;
  .flex1 {
    flex: 1;
    div {
      margin-bottom: 10px;
    }
  }
  .address-title {
    font-weight: 500;
  }
}
</style>
