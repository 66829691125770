<template>
  <div>
    <SlListView
      ref="listView"
      @gotoPage="gotoPage"
      :total="page.total"
      :pageIndex="page.pageIndex"
      :pageSize="page.pageSize"
    >
      <div slot="search">
        <!-- 搜索区域search包含搜索和重置按钮 -->
        <SlSearchForm
          ref="searchForm"
          v-model="formQuery"
          :items="searchItems"
          :loading="loading"
          @reset="reset"
          @search="gotoPage(page.pageSize)"
        ></SlSearchForm>
      </div>
      <!-- tab切换 -->
      <div
        style="width: 100%; display: flex;background: #fff; justify-content: space-between; align-items: center;"
      >
        <SlSwitchNav :navs="switchNavs" :default-active="switchActiveIndex" @select="switchNav">
          <template v-slot:custom="{tab}">{{tab.tabName}}({{tab.count}})</template>
        </SlSwitchNav>
        <el-button
          style="margin-right: 25px;"
          type="primary"
          :loading="exportLoading"
          @click="handleExport"
        >导出</el-button>
      </div>
      <!-- 表格区域包含分页 -->
      <SlTable
        ref="table"
        align="left"
        :selection="false"
        :border="false"
        :tableData="tableData"
        :columns="columns"
        :operate="true"
        :tooltip="false"
        rowKey="id"
      >
        <div slot="moreStockLabelOrderStatusName" slot-scope="{row}">
          <span>{{ row.moreStockLabelOrderStatusName }}</span>
          <!-- moreStockLabelOrderStatus: 0-不需要；1-未创建 2-创建中 3-创建成功 -->
          <el-button
            type="text"
            @click="moreStockLabelOrderClick(row, true)"
            v-if="[2,3].includes(row.moreStockLabelOrderStatus)"
          >(查看详情)</el-button>
        </div>
        <div slot="operation" slot-scope="{row}">
          <el-button type="text" @click="viewDetailInfo(row)">查看</el-button>
          <el-button v-if="showLabelOrderBtn(row)" type="text" @click="labelOrderClick(row)">标签下单</el-button>
          <el-button
            v-if="showMoreStockLabelOrderBtn(row)"
            type="text"
            @click="moreStockLabelOrderClick(row, false)"
          >多货标签下单</el-button>
        </div>
      </SlTable>
    </SlListView>

    <!-- 标签下单 -->
    <LabelOrderDialog
      :visible.sync="labelOrderDialogVisible"
      :selectedRow="selectedRow"
      :initAddress="initAddress"
      @submitSuccess="updateTable"
    ></LabelOrderDialog>

    <!-- 多货标签下单 -->
    <MoreStockLabelOrderDialog
      :visible.sync="moreStockLabelOrderDialogVisible"
      :selectedRow="selectedRow"
      :onlyView="moreStockLabelOnlyView"
      :initAddress="initAddress"
      @submitSuccess="updateTable"
    ></MoreStockLabelOrderDialog>
  </div>
</template>

<script>
import OemGoodsAPI from '@api/oemGoods'
import CommonUrl from '@api/url.js'
import { setSessionItem, getSessionItem, removeSessionItem } from '@shared/util'
import MoreStockLabelOrderDialog from './productionOrderList/MoreStockLabelOrderDialog.vue'
import LabelOrderDialog from './productionOrderList/LabelOrderDialog.vue'

export default {
  name: 'ProductionOrderList',
  components: { LabelOrderDialog, MoreStockLabelOrderDialog },
  data () {
    return {
      loading: false,
      tableData: [],
      switchNavs: [],
      switchActiveIndex: '0',
      formQuery: {},
      page: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      searchItems: [
        {
          type: 'batch-input',
          label: '生产订单号',
          name: 'purchaseOrderNumber'
        },
        {
          type: 'batch-input',
          label: 'SKU编码',
          name: 'sku'
        },
        {
          type: 'batch-input',
          label: 'UPC编码',
          name: 'upcs'
        },
        {
          type: 'input',
          label: '商品名称',
          name: 'commodityName'
        },
        {
          type: 'date',
          label: '下单时间',
          name: 'orderTimes',
          data: {
            datetype: 'daterange',
            isBlock: true
          }
        },
        {
          type: 'single-select',
          label: '快返单',
          name: 'fastOrderFlag',
          data: {
            remoteUrl: CommonUrl.dictUrl,
            params: { dataCode: 'YES_NO' }
          }
        },
        {
          type: 'single-select',
          label: '订单类型',
          name: 'orderProcessType',
          data: {
            remoteUrl: CommonUrl.dictUrl,
            params: { dataCode: 'ORDER_PROCESS_TYPE' }
          }
        }
      ],
      pageFromDetail: false,
      cachePageStatus: {
        switchActiveIndex: '0',
        formQuery: {},
        page: {
          pageIndex: 1,
          pageSize: 10
        }
      },
      exportLoading: false,
      selectedRow: {},
      labelOrderDialogVisible: false,
      moreStockLabelOrderDialogVisible: false,
      moreStockLabelOnlyView: true,
      initAddress: {
        consigneeAddress: '',
        consigneeName: '',
        consigneeNumber: ''
      }
    }
  },
  watch: {
    formQuery: {
      handler () {
        this.updateCachePageStatus()
      },
      deep: true
    },
    labelOrderDialogVisible: {
      handler: function (newVal) {
        if (!newVal) {
          this.selectedRow = {}
        }
      }
    },
    moreStockLabelOrderDialogVisible: {
      handler: function (newVal) {
        if (!newVal) {
          this.selectedRow = {}
        }
      }
    }
  },
  computed: {
    columns () {
      const defaultColumns = [
        {
          prop: 'purchaseOrderNumber',
          label: '生产订单号',
          width: 220,
          render: (h, data) => {
            let { row = {} } = data
            return (
              <p class="flex">{row.purchaseOrderNumber} {row.needRfid && <el-tag class="ml-8px mr-8px">RFID</el-tag>}{row.fastOrderFlag && <el-tag class="ml-8px" type="danger">快返单</el-tag>}</p>
            )
          }
        },
        {
          prop: 'orderProcessTypeName',
          label: '订单类型'
        },
        {
          prop: 'skuQuantity',
          label: 'SKU总数'
        },
        {
          prop: 'requireQuantity',
          label: '订单总件数'
        },
        {
          prop: 'totalAmount',
          label: '订单金额'
        },
        {
          prop: 'warehouseName',
          label: '到货仓库',
          render: (h, data) => {
            let { row = {} } = data
            return (
              <p>
                <span>{row.warehouseName}</span>
                <p>{row.processPlantSupplierName}</p>
              </p>
            )
          }
        },
        {
          prop: 'orderTime',
          label: '下单时间'
        }
      ]
      const dynamicColumns = [
        {
          prop: 'deliveryQuantity',
          label: '总发货数'
        },
        {
          prop: 'inInventoryQuantity',
          label: '总入库数'
        },
        {
          prop: 'lessStockQuantity',
          label: '总少货数'
        }
      ]
      let arr = [].concat(defaultColumns)
      // 送货中、已完成、已取消、已结算状态的生产订单会多展示几列
      if (['2', '3', '4', '5'].includes(this.switchActiveIndex)) {
        arr.splice(4, 0, ...dynamicColumns)
      }
      if (['1'].includes(this.switchActiveIndex)) {
        const moreColumns = [{
          prop: 'labelOrderStatusName',
          label: '标签下单状态'
        }, {
          prop: 'moreStockLabelOrderStatusName',
          label: '多货标签下单状态'
        }]
        arr = arr.concat(moreColumns)
      }
      return arr
    },
    showLabelOrderBtn () {
      return (row) => {
        const { supportTagOrder = false } = row
        // 1: 待发货    2： 送货中
        const allowLabelOrderStatus = [1, 2].includes(Number(this.switchActiveIndex))
        return allowLabelOrderStatus && supportTagOrder
      }
    },
    showMoreStockLabelOrderBtn () {
      return (row) => {
        const { supportMoreStockTagOrder = false } = row
        // 1: 待发货    2： 送货中
        const allowLabelOrderStatus = [1, 2].includes(Number(this.switchActiveIndex))
        return allowLabelOrderStatus && supportMoreStockTagOrder
      }
    },
    labelOrderTable () {
      const { skuList = [] } = this.selectedRow || {}
      return skuList
    }
  },
  created () {
    if (getSessionItem('isFromDetail')) {
      let productionOrderListStatus = JSON.parse(getSessionItem('ProdOrderListStatusCache'))
      this.page = Object.assign({}, productionOrderListStatus.page)
      this.switchActiveIndex = productionOrderListStatus.switchActiveIndex
      this.formQuery = productionOrderListStatus.formQuery
      this.removeSessionItems()
    }
    this.initAddressInfo()
  },
  mounted () { },
  beforeRouteEnter (to, from, next) {
    if (from.path.includes('production-order-detail')) {
      setSessionItem('isFromDetail', true)
    }
    next()
  },

  methods: {
    gotoPage (pageSize = 10, pageIndex = 1) {
      const params = this.generateParams(pageSize, pageIndex)
      this.loading = true
      OemGoodsAPI.getProductionOrderList(params).then(res => {
        let { success, data = {} } = res
        if (success) {
          this.tableData = data.list
          this.page.total = data.total
          this.page.pageIndex = pageIndex
          this.page.pageSize = pageSize
          this.getSwitchNavs()
          this.updateCachePageStatus()
        }
      }).finally(() => {
        this.loading = false
      })
    },
    reset () {
      this.switchActiveIndex = '0'
      Object.keys(this.formQuery).forEach(key => {
        this.formQuery[key] = null
      })
      this.removeSessionItems()
      this.gotoPage(this.page.pageSize)
    },
    getSwitchNavs () {
      OemGoodsAPI.getProductionOrderStatic(this.getQureyParams()).then(res => {
        let { data = [] } = res
        this.switchNavs = data || []
      })
    },
    switchNav (index) {
      this.switchActiveIndex = index
      this.gotoPage()
    },
    viewDetailInfo ({ id }) {
      this.$router.push({
        path: '/home/oem-order-manage/production-order-detail',
        query: {
          purchaseOrderId: id,
          status: this.switchActiveIndex
        }
      })
    },
    labelOrderClick (row) {
      this.selectedRow = row
      this.labelOrderDialogVisible = true
    },
    moreStockLabelOrderClick (row, onlyView) {
      this.moreStockLabelOnlyView = onlyView
      this.selectedRow = row
      this.moreStockLabelOrderDialogVisible = true
    },
    updateTable () {
      const { pageSize, pageIndex } = this.page
      this.gotoPage(pageSize, pageIndex)
    },
    generateParams (pageSize, pageIndex) {
      return {
        ...this.getQureyParams(),
        status: parseInt(this.switchActiveIndex),
        pageIndex,
        pageSize
      }
    },
    getQureyParams () {
      let { orderTimes = [], ...orther } = this.formQuery
      return {
        ...orther,
        orderTimeStart: orderTimes && orderTimes[0] ? orderTimes[0] : '',
        orderTimeEnd: orderTimes && orderTimes[1] ? orderTimes[1] : ''
      }
    },
    updateCachePageStatus () {
      this.cachePageStatus.switchActiveIndex = this.switchActiveIndex
      this.cachePageStatus.formQuery = Object.assign({}, this.formQuery)
      this.cachePageStatus.page = Object.assign({}, this.page)
      setSessionItem('ProdOrderListStatusCache', JSON.stringify(this.cachePageStatus))
    },
    removeSessionItems () {
      removeSessionItem('ProdOrderListStatusCache')
      removeSessionItem('isFromDetail')
    },
    handleExport () {
      this.exportLoading = true
      const pageSize = this.page.pageSize
      const pageIndex = this.page.pageIndex
      const obj = this.generateParams(pageSize, pageIndex)
      const params = {
        ...obj
      }
      OemGoodsAPI.exportProductOrderList(params).then(res => {
        if (res.success === false) return
        const csvData = new Blob([res], { type: 'text/csv;charset=utf-8;' })
        const csvURL = window.URL.createObjectURL(csvData)
        const tempLink = document.createElement('a')
        tempLink.href = csvURL
        tempLink.setAttribute('download', '生产订单列表.csv')
        tempLink.click()
      }).finally(() => {
        this.exportLoading = false
      })
    },
    initAddressInfo () {
      OemGoodsAPI.getSupplierShippingAddress().then(res => {
        let { success, data } = res
        if (success) {
          const { consigneeAddress = '', consigneeName = '', consigneeNumber = '' } = data
          this.initAddress = { consigneeAddress, consigneeName, consigneeNumber }
        } else {
          this.initAddress = { consigneeAddress: '', consigneeName: '', consigneeNumber: '' }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.red-notice-text {
  color: red;
  line-height: 1.2;
  margin-bottom: 10px;
}
.flex {
  display: flex;
}
.label-order-table {
  /deep/ .el-table__body-wrapper {
    max-height: 50vh;
    overflow-y: scroll;
  }
}
</style>
