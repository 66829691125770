<template >
  <SlDialog
    title="收货地址"
    :visible.sync="dialogVisible"
    width="700px"
    :loading="submitLoading"
    @closed="handleClose"
    @close="handleClose"
    @submit="handleSubmit"
    append-to-body
  >
    <el-form
      :model="form"
      class="add-form"
      label-width="120px"
      ref="formRef"
      :rules="formRules"
      label-suffix=":"
    >
      <el-form-item label="收货地址" prop="consigneeAddress">
        <el-input placeholder="请输入" v-model="form.consigneeAddress"></el-input>
      </el-form-item>
      <el-form-item label="收货姓名" prop="consigneeName">
        <el-input placeholder="请输入" v-model="form.consigneeName"></el-input>
      </el-form-item>
      <el-form-item label="收货电话" prop="consigneeNumber">
        <el-input placeholder="请输入" v-model="form.consigneeNumber"></el-input>
      </el-form-item>
    </el-form>
  </SlDialog>
</template>

<script>
import OemGoodsAPI from '@api/oemGoods'

export default {
  name: 'UpdateAddressDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    },
    address: {
      type: Object,
      default: () => {
        return {
          consigneeAddress: '',
          consigneeName: '',
          consigneeNumber: ''
        }
      },
      required: true
    }
  },
  data () {
    return {
      dialogVisible: false,
      submitLoading: false,
      form: {
        consigneeAddress: '',
        consigneeName: '',
        consigneeNumber: ''
      },
      formRules: {
        consigneeAddress: [{ required: true, message: '收货地址不能为空', trigger: 'blur' }],
        consigneeName: [{ required: true, message: '收货姓名不能为空', trigger: 'blur' }],
        consigneeNumber: [{ required: true, message: '收货电话不能为空', trigger: 'blur' }]
      }
    }
  },
  watch: {
    visible: {
      handler: function (newVal) {
        if (this.dialogVisible !== newVal) {
          this.dialogVisible = newVal
        }
      },
      immediate: true
    },
    address: {
      handler: function (newVal) {
        const { consigneeAddress = '', consigneeName = '', consigneeNumber = '' } = newVal || {}
        this.form = { consigneeAddress, consigneeName, consigneeNumber }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleSubmit () {
      this.submitLoading = true
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          OemGoodsAPI.updateSupplierShippingAddress({ ...this.form }).then(res => {
            let { success } = res
            if (success) {
              this.$message.success('更新地址成功')
              this.dialogVisible = false
              this.$emit('submitSuccess', this.form)
              this.$emit('update:visible', false)
            }
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          this.submitLoading = false
        }
      })
    },
    handleClose () {
      this.dialogVisible = false
      this.$emit('update:visible', false)
    }
  }
}
</script>
